import * as React from "react";
import { css } from "aphrodite";
import { H1Style } from "./H1Style";

interface IProps {
  children: React.ReactNode;
  styles?: any;
}

export const H1: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { styles, children } = props;
  return <h1 className={css(H1Style.h1, styles)}>{children}</h1>;
};
