import { StyleSheet } from "aphrodite";
import {
  fontSize,
  lineHeight,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import { textColor } from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";

interface IStyle {
  h1: any;
}

export const H1Style: IStyle = StyleSheet.create({
  h1: {
    fontSize: fontSize.title,
    lineHeight: lineHeight.title,
    fontWeight: fontWeight.bold,
    color: textColor.primaryDarkColor,
    padding: `calc(${space.atom} - (${lineHeight.title} - ${fontSize.title})/2) ${space.atom}`,
    margin: 0,
  },
});
