import { createAction, ActionFunction1, Action } from "redux-actions";
import { SET_ACTIVE_BREAKPOINT } from "main/javascripts/constants/actionType/BreakpointActionType";
import { IBreakpointState } from "main/javascripts/reducers/BreakpointReducer";

type ISetActiveBreakpoint = keyof IBreakpointState;

export const setActiveBreakpoint: ActionFunction1<
  ISetActiveBreakpoint,
  Action<ISetActiveBreakpoint>
> = createAction<ISetActiveBreakpoint, ISetActiveBreakpoint>(
  SET_ACTIVE_BREAKPOINT,
  (param: ISetActiveBreakpoint): ISetActiveBreakpoint => {
    return param;
  }
);
