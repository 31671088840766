import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import dynamic from "next/dynamic";
const Header = dynamic(() =>
  process.env.HOSTING_DOMAIN
    ? import("main/javascripts/components/organisms/Header").then(
        (mod) => mod.HeaderTravelvision
      )
    : import("main/javascripts/components/organisms/Header").then(
        (mod) => mod.Header
      )
);
import { IProps } from "main/javascripts/components/organisms/Header";
import { RootState } from "main/javascripts/store";
import { setActiveBreakpoint } from "main/javascripts/actions/Breakpoint";

const mapStateToProps: any = (state: RootState): IProps => {
  return {
    currentUser: state.currentUser.currentUser, // RTK
  };
};

const mapDispatchToProps: any = (dispatch: any): any => {
  return {
    actions: bindActionCreators(
      {
        setActiveBreakpoint,
      },
      dispatch
    ),
  };
};

export const HeaderContainer: any = connect<IProps, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(Header);
