import { StyleSheet } from "aphrodite/no-important";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";

export const GLOBALS = "__GLOBAL_STYLES__";

const globalExtension: any = {
  selectorHandler: (
    selector: any,
    baseSelector: any,
    generateSubtreeStyles: any
  ): any => {
    return baseSelector.includes(GLOBALS)
      ? generateSubtreeStyles(selector)
      : null;
  },
};

export const extended: any = StyleSheet.extend([globalExtension]);
// productionでclass nameがminifyされるとGLOBALSを付与せずバグるので、falseにする
extended.minify(false);

export const defaultStyles: any = extended.StyleSheet.create({
  [GLOBALS]: {
    "*": {
      margin: "0px",
      padding: "0px",
    },
    html: {
      fontSize: "100%",
      boxSizing: "border-box",
      [moreThanBreakpoint("desktop")]: {
        fontSize: "93.75%",
      },
    },
    body: {
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif',
      backgroundColor: "#fff",
      "-webkit-text-size-adjust": "100%",
    },
    "*::-webkit-input-placeholder": {
      color: "#aaa",
      lineHeight: "1.2",
    },
    hr: {
      display: "block",
      height: "1px",
      border: 0,
      borderTop: "1px solid #ccc",
      margin: "1em 0",
      padding: 0,
    },
    ul: {
      paddingLeft: "1.3em",
      listStyle: "square",
    },
  },
});
