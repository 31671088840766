import * as React from "react";
import { css } from "aphrodite";
import { MainBlockStyle } from "./MainBlockStyle";

interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  styles?: any;
}

export const MainBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { styles, children, ...rest } = props;
  return (
    <div className={css(MainBlockStyle.block, styles)} {...rest}>
      {children}
    </div>
  );
};
