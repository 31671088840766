import { connect } from "react-redux";

import dynamic from "next/dynamic";
const Footer = dynamic(() =>
  process.env.HOSTING_DOMAIN
    ? import("main/javascripts/components/organisms/Footer").then(
        (mod) => mod.FooterTravelvision
      )
    : import("main/javascripts/components/organisms/Footer").then(
        (mod) => mod.Footer
      )
);
// import { Footer, IProps } from "main/javascripts/components/organisms/Footer";
import { RootState } from "main/javascripts/store";

const mapStateToProps: any = (state: RootState): any => {
  return {
    currentUser: state.currentUser.currentUser, // RTK
  };
};

export const FooterContainer: any = connect<any, any, any>(
  mapStateToProps,
  {}
)(Footer);
