import { useState } from "react";

export const useConstructor: (callback: any) => any = (callback: any): any => {
  const [isConstructed, setIsConstructed] = useState(false);
  if (isConstructed) {
    return [isConstructed, setIsConstructed];
  }
  callback();
  setIsConstructed(true);
  return [isConstructed, setIsConstructed];
};
