import * as React from "react";
import { css } from "@emotion/react";
import { parse, stringify } from "qs";
import { H1 } from "main/javascripts/components/atoms/H1";
import { Pager } from "main/javascripts/components/pager/Pager";
import { TagList } from "main/javascripts/features/post/components/TagList";
import { RegionTagList } from "main/javascripts/components/molecules/Post/RegionTagList";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import { PopularTagList } from "main/javascripts/components/molecules/Post/PopularTagList";
import { RankingList } from "main/javascripts/features/post/components/RankingList";
import { POST_SLUG } from "main/javascripts/constants/PostConstants";
import { useAppSelector } from "main/javascripts/store";
import {
  numPerPageSelector,
  pageSelector,
  popularTagsSelector,
  postsSelector,
  rankingsSelector,
  regionSelector,
  tagSelector,
  totalNumSelector,
} from "../postSlice";
import {
  backgroundColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";

export const PostIndexMain: React.FC = (): React.ReactElement => {
  const posts = useAppSelector(postsSelector);
  const totalNum = useAppSelector(totalNumSelector);
  const page = useAppSelector(pageSelector);
  const numPerPage = useAppSelector(numPerPageSelector);
  const region = useAppSelector(regionSelector);
  const rankings = useAppSelector(rankingsSelector);
  const popularTags = useAppSelector(popularTagsSelector);
  const tag = useAppSelector(tagSelector);

  const pagerHandler: (page: number) => void = async (page: number) => {
    const param = parse(window.location.search, { ignoreQueryPrefix: true });
    const newParam = { ...param, page: page };
    const queryString = stringify(newParam, { arrayFormat: "brackets" });
    window.location.href = `${window.location.pathname}?${queryString}`;
  };

  let title = "";
  if (tag) {
    title = tag.name;
  } else if (region) {
    title = region.name;
  }
  return (
    <div css={blockStyle}>
      <main css={innerStyle}>
        <div css={mainStyle}>
          {title && (
            <div css={titleBlockStyle}>
              <H1>{title}</H1>
            </div>
          )}
          <div css={postListBlockStyle}>
            {posts.map((post) => {
              const hasTags: boolean =
                post.tagList?.length > 0 || post.regions?.length > 0;
              return (
                <div key={post.id} css={postBlockStyle}>
                  <a
                    css={postBlockInnerStyle}
                    href={`${PATH_SCOPE}/${POST_SLUG}/${post.id}`}
                  >
                    <div css={featuredImageBlockStyle}>
                      {post.featuredImage && (
                        <img
                          css={featuredImageStyle}
                          src={post.featuredImage.url}
                          alt={post.title}
                        />
                      )}
                    </div>
                    <div css={contentBlockStyle}>
                      <h2 css={postTitleStyle}>{post.title}</h2>
                      {hasTags && (
                        <div css={tagBlockStyle}>
                          {post.tags.length > 0 && (
                            <div css={tagListBlockStyle}>
                              <TagList tags={post.tags} disableLink />
                            </div>
                          )}
                          {post.regions.length > 0 && (
                            <RegionTagList
                              regions={post.regions}
                              disableLink={true}
                            />
                          )}
                        </div>
                      )}
                      {post.summary && (
                        <div css={postSummaryStyle}>{post.summary}</div>
                      )}
                      {post.editor && (
                        <div css={editorProfileBlockStyle}>
                          <div>{post.editor.name}</div>
                        </div>
                      )}
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
          <div css={pagerBlockStyle}>
            <Pager
              totalNum={totalNum}
              page={page}
              numPerPage={numPerPage}
              handler={pagerHandler}
            />
          </div>
        </div>
        <div css={sideStyle}>
          <div css={popularTagsBlockStyle}>
            <PopularTagList tags={popularTags} />
          </div>
          <div css={rankingBlockStyle}>
            <RankingList rankings={rankings} />
          </div>
        </div>
      </main>
    </div>
  );
};

const blockStyle = css`
  background-color: ${backgroundColor.secondaryLightColor};
  ${moreThanBreakpoint("desktop")} {
    min-height: calc(100vh - 357px);
  }
`;
const innerStyle = css`
  max-width: 50rem;
  margin: 0 auto;
  padding: ${space.atom} 0;
  ${moreThanBreakpoint("tablet")} {
    padding: ${space.atom3x} ${space.atom3x};
  }
  ${moreThanBreakpoint("desktop")} {
    max-width: 72rem;
    display: flex;
    justify-content: space-between;
    padding: ${space.atom3x} ${space.atom3x} 3rem;
  }
`;
const mainStyle = css`
  ${moreThanBreakpoint("desktop")} {
    width: calc(100% - 21.5rem);
  }
`;
const sideStyle = css`
  padding-top: ${space.atom4x};
  ${moreThanBreakpoint("desktop")} {
    padding-top: 0;
    width: 20rem;
  }
`;
const titleBlockStyle = css`
  padding: ${space.atom};
  background-color: #fff;
  border-bottom: 1px solid ${borderColor.primaryLightColor};
  ${moreThanBreakpoint("desktop")} {
    padding: ${space.atom} ${space.atom1_5x};
  }
`;
const postListBlockStyle = css`
  width: 100%;
  background-color: #fff;
`;
const postBlockStyle = css`
  border-top: 1px solid ${borderColor.primaryLightColor};
  &:first-child {
    border-top: none;
  }
`;
const postBlockInnerStyle = css`
  display: flex;
  align-items: flex-start;
  padding: ${space.atom} ${space.atom};
  text-decoration: none;
  color: ${textColor.primaryDarkColor};
  transition: background 0.5s ease;
  ${moreThanBreakpoint("tablet")} {
    padding: ${space.atom3x} ${space.atom3x};
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;
const featuredImageBlockStyle = css`
  position: relative;
  margin: ${space.atom};
  width: 22%;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
    background-color: ${backgroundColor.secondaryLightColor};
  }
`;
const featuredImageStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%);
  @supports (object-fit: cover) {
    object-fit: cover;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
  }
`;
const contentBlockStyle = css`
  width: 78%;
  box-sizing: border-box;
  ${moreThanBreakpoint("tablet")} {
    padding: 0 0 0 ${space.atom2x};
  }
`;
const postTitleStyle = css`
  color: ${textColor.primaryDarkColor};
  padding: ${space.atom0_5x} ${space.atom};
  margin: 0.125rem 0 0;
  font-size: ${fontSize.mediumHeading};
  line-height: ${lineHeight.mediumHeadline2};
  font-weight: ${fontWeight.bold};
  ${moreThanBreakpoint("tablet")} {
    padding: ${space.atom0_5x} ${space.atom};
    font-size: ${fontSize.headline};
    line-height: ${lineHeight.headline1};
  }
`;
const postSummaryStyle = css`
  padding: ${space.atom} ${space.atom};
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption2};
  color: ${textColor.secondaryDarkColor};
`;
const editorProfileBlockStyle = css`
  padding: 0 ${space.atom};
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption2};
  color: ${textColor.secondaryDarkColor};
  opacity: 0.7;
  text-align: right;
`;
const pagerBlockStyle = css`
  max-width: 30rem;
  margin: ${space.atom4x} auto ${space.atom4x};
`;
const tagBlockStyle = css`
  padding: ${space.atom} 0 0;
`;
const tagListBlockStyle = css`
  padding: 0 ${space.atom};
`;
const popularTagsBlockStyle = css`
  padding: 0 0 ${space.atom2x};
`;
const rankingBlockStyle = css``;
